<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Software Developer
                                <router-link to="/careers/software-developer/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for highly skilled problem solvers and creative thinkers that can work together to deliver superior tech support and services to our clients. Using the most advanced toolsets, we help our clients drive down costs, improve business efficiency, enhance their ability to manage risk, and ultimately increase their bottom line.</p>
                                <p>In this technical support role, you will be responsible for helping to drive our company's growth together in terms of great customer service and play a key role in establishing as a value-added business partner to other organizations. You will be the 'face' of our company and work on troubleshooting and successfully resolving technical support issues by engaging directly with customers. A strong background in IT support will be necessary for this role. If you are looking for a career where you can climb up the ranks quickly, look no further!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Job Responsibilities
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Develop integration interface across JAVA, .NET, C++, and other platforms.</li>
                                            <li><i class="icon-ok"></i>Define system specifications, input/output processes, and hardware or software compatibility.</li>
                                            <li><i class="icon-ok"></i>Extend technical support during preparation, installation, and system maintenance.</li>
                                            <li><i class="icon-ok"></i>Create professional technical documentation and present it for internal and external use.</li>
                                            <li><i class="icon-ok"></i>Develop and automate change validation to tools before roll out and handle validation.</li>
                                            <li><i class="icon-ok"></i>Contribute towards team, department, and company goals.</li>
                                            <li><i class="icon-ok"></i>Integrate interface together with project managers.</li>
                                            <li><i class="icon-ok"></i>Execute and upgrade integrated interfaces.</li>
                                            <li><i class="icon-ok"></i>Assist clients to test integrated interfaces.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>3+ years relevant work experience in Microsoft .Net using C# </li>
                                            <li><i class="icon-ok"></i>Experience in NodeJS, package managers, and build tool</li>
                                            <li><i class="icon-ok"></i>Experience with using JAVA, .NET and C++ applications</li>
                                            <li><i class="icon-ok"></i>Experience in building custom Integrations for Microsoft Teams, Azure, and Business Central(<a href="https://dynamics.microsoft.com/en-us/business-central/overview)">https://dynamics.microsoft.com/en-us/business-central/overview)</a></li>
                                            <li><i class="icon-ok"></i>Experienced with Microsoft Teams 3rd party app development</li>
                                            <li><i class="icon-ok"></i>Familiarity with DevOps/Azure is a PLUS!</li>
                                            <li><i class="icon-ok"></i>Expertise with Modern Architecture/Dev practices</li>
                                            <li><i class="icon-ok"></i>Strong understanding of Object-Oriented Programming (OOP)</li>
                                            <li><i class="icon-ok"></i>Proficient with REST API design and general Web API development</li>
                                            <li><i class="icon-ok"></i>Significant experience in database design and SQL - relational databases</li>
                                            <li><i class="icon-ok"></i>Relevant experience in software development for projects from the ground up</li>
                                            <li><i class="icon-ok"></i>Relevant experience in using build tools and package managers</li>
                                            <li><i class="icon-ok"></i>Proficient in writing clean code while applying both functional and object-oriented programming</li>
                                            <li><i class="icon-ok"></i>Excellent communication skills</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            FAQ
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-question"></i>Is this a work-from-home position? - Yes, we are offering a permanent WFH position.</li>
                                            <li><i class="icon-question"></i>Is this a graveyard shift? - Yes, the client we support is in the US, so this will need to support US business hours.</li>
                                            <li><i class="icon-question"></i>Is this an FT position - Yes, this is a full-time position with a set shift. If you are looking to continue a side job or continue freelancing, we require our employees to not take on additional work that  competes with eFlex and our clients.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary and Benefits package. This includes a comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>A temporary work-from-home setup as we prepare to provide a safe environment for our employees who would like to work in the office. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/software-developer/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>